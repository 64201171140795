body {
  margin: 0;  
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  background-color: #081136;
  color: #fff;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #c7ff31;
}
.page_header {
  background: #675CE4 url("./assets/images/main_banner.png") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 270px;
  position: relative;
  margin-top: 50px;
  border-radius: 5px;
  z-index: 10;
}
.page_header::before {
  content: "";
  background: linear-gradient(85.68deg, #6B5CE4 25.47%, rgba(107, 77, 225, 0.9) 46.76%, rgba(102, 82, 223, 0) 79.24%, rgba(233, 246, 255, 0) 96.3%);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: -1;
}
.page_header h1 {
  font-family: 'Montserrat', sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 48px;
  line-height: 105%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  max-width: 35%;
  height: 100%;
  margin-left: 10%;
}
.d_d_none {
  display: none !important;
}
.main {
  min-height: 80vh;
}
.individual_collections_panel {
  display: flex;
  flex: 1 1;
  margin: 80px 0 50px;
}
.individual_collections_panel_left {
  width: 300px;
  min-width: 300px;
}
.individual_collections_panel_right {
  width: calc(100vw - 340px);
  padding-left: 15px;
}
.collection_filter_panel {
  margin-bottom: 30px;
  background: #04061F;
}
.individual_collections_panel_left_head {
  margin-bottom: 15px;
}
.filter_close {
  font-size: 24px;
}
.collection_filter_panel .accordion-item {
  margin-bottom: 5px;
  background-color: #04061F;
  border: none;  
}
.collection_filter_panel .accordion-button {
  background: #151F49;
  box-shadow: none !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  text-transform: uppercase;
}
.collection_filter_panel .accordion-button::after {
  background: url("./assets/images/side_arrow.png") no-repeat;
  width: 8px;
  height: 12px;
}
.collection_filter_panel .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/down_arrow.png") no-repeat;
  width: 12px;
  height: 8px;
  transform: rotate(360deg);
}
.game_filter,
.rarity_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.game_filter div,
.rarity_filter div {
  display: flex;
  align-items: center;  
}
.game_filter div img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
  border: 2px solid transparent;
}
.rarity_filter div img {
  margin-right: 10px;
}
.game_filter div h4,
.rarity_filter div h4 {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}
.collection_filter_panel .bi {
  color: rgba(255, 255, 255, 0.4);
}
.game_filter_selected div img {
  border: 2px solid #C7FF31;
}
.game_filter_selected div h4,
.rarity_filter_selected div h4 {
  color: #C7FF31;
  font-weight: 800;
}
.range_slider_wrap {
  position: relative;
}
.range {
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.range::-ms-tooltip {
  display: none;
}
.track {
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.track-inner {
  width: 0;
  height: 100%;
  background: #C7FF31;
}
.thumb {
  width: 16px;
  height: 16px;
  background: #C7FF31;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  pointer-events: none;
}
.range_value_inp {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.range_value_inp input {
  background: rgba(8, 17, 54, 0.9);
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin: 0 5px;
}
.range_value_inp input:focus {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(8, 17, 54, 0.9);
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked ~ label {
  color: #c7ff31;
}
.form-check-input:focus {
  box-shadow: none;
}
.form-check {
  position: relative;
  padding-left: 35px;
}
.form-check-input:checked::before {
  content: "\f62a";
  color: rgba(255, 255, 255, 0.4);
  font-family: bootstrap-icons !important;
  font-size: 24px;
  position: absolute;
  right: -250px;
  top: -9px;
  cursor: pointer;
}
.custom_checkbox input[type="checkbox"] {
  position: relative;
  background-image: none !important;
  background-color: transparent;
  border: none;
}
.custom_checkbox input[type="checkbox"] + label {
  display:inline-block;
  position: relative;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
}
.custom_checkbox input[type="checkbox"] + label:hover {
  color: #C7FF31;
}
.custom_checkbox input[type="checkbox"] + label:before {
  content:'';
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: transparent;
  left: -35px;
  top: 4px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.custom_checkbox input[type="checkbox"]:checked + label:after {
  content:'';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #C7FF31;
  left: -30px;
  top: 9px;
  border-radius: 50%;
}
.custom_checkbox input[type="checkbox"]:checked + label:before {
  border: 1px solid #C7FF31;
}
.individual_collections_panel_right_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #04061F;
  border-radius: 5px;
  padding: 10px;
}
.individual_collections_panel_right_top > div:nth-child(2) {
  display: flex;
  align-items: center;
}
.collections_tab {
  border-bottom: none;
}
.collections_tab.nav-tabs .nav-link {
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 0;
  margin: 0 15px 0 10px;
}
.collections_tab.nav-tabs .nav-item.show .nav-link, 
.collections_tab.nav-tabs .nav-link.active,
.collections_tab.nav-tabs .nav-link:focus, 
.collections_tab.nav-tabs .nav-link:hover {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #C7FF31;
  color: #C7FF31;
}
.collections_filter_results {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.collections_filter_results .alert {
  width: fit-content;
  padding: 5px 15px;
  background: #04061F;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0;
}
.collections_filter_results .alert p {
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: capitalize;
}
.collections_filter_results .alert-dismissible .close {
  position: relative;
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
  padding: 0;
  background: transparent;
  border: none;
  margin-left: 10px;
  font-size: 22px;
}
.collections_filter_results a {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.collections_filter_results_items {
  margin-right: 15px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
}
.collections_filter_results_items p {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 5px;
}
.price_filter {
  background: rgba(8, 17, 54, 0.9);
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6) !important;
  text-transform: capitalize;
  transition: 0.2s all;
}
.price_filter:hover {
  color: #C7FF31 !important;
}
.price_filter img {
  margin-right: 3px;
  filter: grayscale(1) contrast(0.8);
}
.price_filter:hover img {
  filter: unset;
}
.dropdown-menu {
  background: #081136;
  box-shadow: 0px 6px 30px rgba(4, 6, 31, 0.7);
  border-radius: 5px;
  padding: 0;
}
.dropdown-item {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #151F49;
  padding: 10px;
}
.dropdown-item:last-child {
  border-bottom: none;
}
.dropdown-item:hover {
  background-color: transparent;
  color: #C7FF31;
}
.btn-check:focus+.btn, .btn:focus {
  box-shadow: none;
}
.searchbox {
  position:relative;
  min-width:38px;
  width:auto;
  overflow:hidden;
  transition: width 0.3s;
  border-radius: 100px;
  margin-left: 15px;
}
.searchbox-input{
  top:0;
  right:0;
  border:0;
  outline:0;
  background: rgba(8, 17, 54, 0.9);;
  width:100%;
  margin:0;
  padding: 7px 55px 7px 20px;
  color: #C7FF31;
  font-weight: 500;
  font-size: 16px;
}
.searchbox-input::-webkit-input-placeholder,
.searchbox-input:-moz-placeholder,
.searchbox-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.searchbox-icon {
  width:38px;
  height:38px;
  display:block;
  position:absolute;
  top:-8px;
  right:0;
  padding:0;
  margin:0;
  border:0;
  outline:0;
  line-height:50px;
  text-align:center;
  cursor:pointer;
  color:#dcddd8;
  background:rgba(8, 17, 54, 0.9);  
}
.searchbox-icon img {
  filter: grayscale(1) contrast(0.8);
}
.searchbox-input:focus ~ .searchbox-icon img,
.searchbox-input:focus-visible ~ .searchbox-icon img {
  filter: unset;
}
.grid_cols {
  row-gap: 20px;
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(4,minmax(0,1fr));
  margin-top: 30px;
}
.nft_item {
  background: #04061F;
  border: 1px solid #04061F;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  padding: 10px;
  text-align: center;
}
.nft_item:hover {
  border: 1px solid rgba(199, 255, 49, 0.6);
}
.collections_div_img {
  overflow: hidden;
}
.collections_div_img img,
.collections_div_img object {
  height: 140px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1) 0ms, -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.nft_item:hover .collections_div_img img {
  transform: scale(1.2);  
}
.nft_item a {
  text-decoration: none;
}
.collections_div_content {
  text-align: center;
  min-height: 116px;
}
.collections_div_content h2 {
  font-weight: 800;
  font-size: 18px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin: 15px 0 10px;
}
.collections_div_content h4 {
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15px;
}
.collections_div_content h5 {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 14px;
}
.collections_div_content h5 span {
  font-weight: 500;
  font-size: 16px;
  color: #C7FF31;
}
.collections_div_content hr {
  color: #151F49;
  opacity: 0.5;
}
.primary_btn {
  position: relative;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 500px;
  margin: 0 10px;
  padding: 7px 36px;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  color: #081136;
  overflow: hidden;
  box-shadow: none;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
}
.primary_btn:active {
  transform: translateY(3px);
}
.primary_btn::after, 
.primary_btn::before {
  border-radius: 500px;
}
.primary_btn:hover {
  color: #081136;
}
.primary_btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s, border 0.3s;
}
.primary_btn:hover::before {
  opacity: 0;
}
.primary_btn::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -35px;
  width: 200px;
  height: 200px;
  background-color: #fff;
  border-radius: 500px;
  transform: translate(-10px, -70px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.primary_btn:hover::after {
  opacity: 1;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.nft_item_rarity_list {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  z-index: 1;
}
.nft_item_rarity_list li {
  background: #04061F;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 12px;
}
#inventory .collections_div_content h5 {
  color: #C7FF31;
}
.primary_btn_border:active {
  transform: translateY(3px);
}
.primary_btn_border::after, 
.primary_btn_border::before {
  border-radius: 500px;
}
.primary_btn_border {
  background-color: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: none;
  position: relative;
  padding: 7px 36px;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 500px;
  margin: 0 10px;
  border: 1px solid transparent;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
}
.primary_btn_border:hover {
  color: #081136;
}
.primary_btn_border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.8);
  transition: opacity 0.3s, border 0.3s;
}
.primary_btn_border:hover::before {
  opacity: 0;
}
.primary_btn_border::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -40px;
  width: 200px;
  height: 200px;
  background-color: #FFFFFF;
  border-color: transparent;
  border-radius: 500px;
  transform: translate(-10px, -70px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.primary_btn_border:hover::after {
  opacity: 1;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.load_more_btn {
  background: rgba(4, 6, 31, 0.7);
  border: 1px solid rgba(4, 6, 31, 0.7);
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(199, 255, 49, 0.6);
  width: 100%;
  padding: 11px;
  margin-top: 30px;
}
.collections_filter_results_mobile {
  display: none;
}
.green_btn {
  background: #C7FF31;
  border-radius: 100px;
  color: #081136;
  font-weight: 800;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  border: none;
  padding: 12px 30px;
  text-transform: uppercase;
  min-width: 175px;
  transition: 0.2s all;
}
.green_btn:hover {
  background: #9CCE16;
}
.reset_btn {
  color: #C7FF31;
  font-weight: 800;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  background: transparent;
  border: none;
  text-transform: uppercase;
}
/* Buy Page Css */
.inner_pageheader {
  min-height: 98vh;
  padding: 50px 0;
}
.collections_details_img {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.collections_details_img img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.collections_details_content h2 {
  font-size: 22px;
  font-weight: 800;
  line-height: 27px;
  font-family: 'Montserrat', sans-serif;
  color: #C7FF31;
  text-transform: uppercase;
}
.collections_details_content h5 {
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
}
.detail_icon_div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.detail_icon_div div {
  background: #04061F;
  border-radius: 1px;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.detail_icon_div div span {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 17px;
}
.collections_details_content hr {
  color: #151F49;
  opacity: 1;
}
.collections_details_content p {
  font-size: 17px;
  line-height: 138%;
  color: #FFFFFF;
}
.collections_details_content h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.8);
  text-transform: capitalize;
}
.collections_details_content h4 span {
  color: #C7FF31;
}
.detail_subtitle {
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
}
.detail_subtitle_line::after {
  content: "";
  background-color: #151F49;
  width: 91%;
  height: 1px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.properties_grid_div .custom_checkbox input[type="checkbox"] + label {
  text-align: center;
}
.properties_grid_div .custom_checkbox input[type="checkbox"] + label:before {
  left: 0;
  bottom: 20px;
  top: unset;
  right: 0;
  margin: 0 auto;
}
.properties_grid_div .custom_checkbox input[type="checkbox"]:checked + label:after { 
  left: 0;
  bottom: 25px;
  top: unset;
  right: 0;
  margin: 0 auto;
}
.properties_grid_div .form-check {
  padding: 0;
}
.properties_grid_div .form-check-input:checked::before {
  display: none;
}
.properties_grid {
  display: grid;
  grid-template-columns: repeat(6,minmax(0,1fr));
  margin-top: 15px;
  column-gap: 12px;
  row-gap: 15px;
}
.properties_grid_div ~ .form-check-input:checked {
  border: 1px solid rgba(199, 255, 49, 0.6);
}
.checkbox-alias{
  z-index: 1;
  transition: all 250ms ease-out;
  background: #04061F;
  border: 1px solid #04061F;
  border-radius: 5px;  
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  flex-direction: column;
}
.properties_grid_div input[type=checkbox]{
  display: none;
}
.properties_grid_div input[type=checkbox]:checked + .checkbox-alias{
  border: 1px solid rgba(199, 255, 49, 0.6);
}
.properties_grid_div .checkbox-alias:hover {
  border: 1px solid rgba(199, 255, 49, 0.6);
}
.properties_grid_div p {
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin: -20px 0 10px;
}
.properties_grid_div h2 {
  font-style: italic;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #C7FF31;
  font-family: 'Montserrat', sans-serif;
}
.properties_grid_div h4 {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
}

.primary_table tbody {
  width: 100%;
  /* display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 140px; */
  margin-bottom: 10px;
}
.primary_table tbody td,
.primary_table thead th {
  /* display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0%; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  white-space: nowrap;
}
.primary_table tbody td {
  color: #808080;
  border: 0;
}
.primary_table thead th {
  border-bottom: 1px solid #d8d8d8;
}
.primary_table .rdt_TableHeadRow {
  background: #151F49;
}
.primary_table .rdt_Table,
.primary_table .rdt_TableRow,
.primary_table .rdt_Pagination,
.primary_table .rdt_Table > div {
  background-color: transparent;
  color: #fff;
}
.primary_table .rdt_TableCol {
  font-size: 16px;
  line-height: 138%;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.primary_table .rdt_TableCell {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.primary_table .rdt_TableCell a {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
}
.primary_table .rdt_TableCell a:hover {
  text-decoration: underline;
}
.primary_table .rdt_TableRow {
  padding: 15px 0;
  border-bottom-color: #151F49 !important;
}
.primary_table .rdt_Pagination {
  color: rgba(255, 255, 255, 0.6);
  border-top-color: #151F49 !important;
}
.primary_table .rdt_Pagination button {
  fill: rgba(255, 255, 255, 0.6);
}
.primary_table .rdt_Pagination button:disabled {
  fill: rgb(128 128 128 / 50%);
}
.green_txt {
  color: #C7FF31 !important;
}
.primary_table {
  border: 1px solid #151F49
}
.back_link {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 15px;
  display: inline-block;
}
.tertiary_btn {
  min-width: 175px;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  padding: 12px 30px;
  background: transparent;
  transition: 0.2s all;
}
.tertiary_btn:hover {
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  color: #081136;
}
.grp_btns button:nth-child(2) {
  margin-left: 15px;
}
.primary_inp {
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  background: transparent;
  width: 100%;
  height: 40px;
  padding: 5px 12px;
  font-weight: 400;
  font-size: 17px;
  color: #FFFFFF;
}
.inp_grp {
  position: relative;
  width: 350px;
  margin-bottom: 20px;
}
.inp_grp label {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  font-size: 16px;
}
img.edit_icon {
  filter: grayscale(1) contrast(0.2);
  position: absolute;
  top: 36px;
  right: 15px;
}
.primary_inp:focus {
  border: 1px solid rgba(199, 255, 49, 0.6);
}
input:focus-visible {
  outline: none;
}
.primary_inp:focus ~ img.edit_icon {
  filter: unset;
}
.primary_modal .modal-header,
.primary_modal .modal-footer {
  border: none;
}
.primary_modal .modal-content {
  background: #04061F;
  border-radius: 5px;
}
.primary_modal .modal-body {
  text-align: center;
  padding: 100px 80px 120px;
}
.primary_modal .modal-body h2 {
  color: #C7FF31;
  font-weight: 800;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}
.primary_modal .modal-body p {
  font-weight: 400;
  font-size: 17px;
  line-height: 138%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 50px;
}
.primary_modal .modal-header .btn-close {
  width: 12px;
  height: 12px;
  filter: invert(1);
  padding: 0;
}
.modal-backdrop {
  background: rgba(54, 68, 126, 0.9);
}
body.modal-open .page_main_div,
body.modal_open_custom .page_main_div {
  filter: blur(12px);
}
.errormessage{
  color:red;
}
.loading_wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}
.primary_modal .modal-body.p-10 {
  padding: 20px;
}
.loading {
  margin: 15px auto 0;
}
.short_address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 180px;
}
.owner_info {
  margin-left: 15px;
  height: 200px;
  overflow: auto;
}
.owner_info > div {
  padding: 8px 0 15px;
  border-bottom: 1px solid #151f49;
}
.owner_info p {
  font-size: 14px;
  margin-bottom: 10px;
}
.owner_info .green_btn {
  font-size: 12px;
  padding: 5px 20px;
  min-width: auto;
  margin-top: 5px;
}
.bi-arrow-clockwise{
  cursor: pointer;
}
.purchasenft{
  text-decoration: underline !important;
}


/* Media Queries */
@media only screen and (min-width: 1400px) {
  .container {
    max-width: calc(100% - 80px)
  }
}
@media only screen and (max-width: 1366px) {
	.grid_cols {		
		grid-template-columns: repeat(3,minmax(0,1fr));
	}
  .detail_subtitle_line::after {
    width: 88%;
  }
}
@media only screen and (max-width: 1199px) {  
  .container {
    max-width: 100%;
  }
  .individual_collections_panel_left_mobile {   
    left: 0 !important; 
  }
  .individual_collections_panel_left {
    background: #04061f;
    z-index: 9999;
    padding: 0;
  }
  .individual_collections_panel_left {
    position: fixed;
    left: -350px;
    top: 0;
    bottom: 0;
    transition: left .5s ease;
  }
  .individual_collections_panel_right {
    width: 100%;
    padding-left: 0;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .mobile_filter {
    margin-left: 15px;
  }
  body.filter_overlay {
    overflow: hidden;
    position: relative;
  }
  body.filter_overlay:after {
    background-color: #000;
    content: "";
    height: 100%;
    left: 0;
    opacity: .8;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1500;
  }
  .individual_collections_panel_left_head {
    align-items: center;
    display: flex!important;
    justify-content: space-between;
    position: relative;
    margin: 20px 0 10px 20px;
  }
  .individual_collections_panel_left_head .filter_close {
    font-size: 32px;
    position: absolute;
    right: -50px;
    color: #C7FF31;
  }
  .individual_collections_panel_left_head h5 {
    font-weight: 400;
    font-size: 16px;
    color: #C7FF31;
  }
  .collection_filter_panel {
    overflow: auto;
    height: 100%;
  }
  .collection_filter_panel .accordion-item {
    margin-bottom: 2px;
  }  
  .individual_collections_panel_right_top > div:nth-child(2) > div {
    display: flex;  
    align-items: center;
  }
  .properties_grid {
    grid-template-columns: repeat(5,minmax(0,1fr));
  }
}
@media only screen and (max-width: 991px) {
  .individual_collections_panel {
    position: relative;
  }
  .collections_tab {
    position: absolute;
    width: 100%;
    top: -50px;
    left: 0;
    display: flex;
    justify-content: space-around;
  }
  .collections_tab.nav-tabs .nav-item.show .nav-link, 
  .collections_tab.nav-tabs .nav-link.active, 
  .collections_tab.nav-tabs .nav-link:focus, 
  .collections_tab.nav-tabs .nav-link:hover {
    border-bottom: none !important;
    background: #081136;
  }
  .collections_tab li.nav-item {
    width: 50%;
    background: rgba(4, 6, 31, 0.7);
  }
  .collections_tab.nav-tabs .nav-link {
    width: 100%;
    margin: 0;
    height: 45px;
  }
  .individual_collections_panel_right_top {
    margin-top: 15px;
  }
  .individual_collections_panel_right_top > div:nth-child(2) {
    width: 100%;
    justify-content: space-between;
  }
  .properties_grid {
    grid-template-columns: repeat(4,minmax(0,1fr));
  }
  .detail_subtitle_line::after {
    width: 85%;
  }
}
@media only screen and (max-width: 767px) {
  .individual_collections_panel {   
    margin: 50px 0 50px;
  }
  .searchbox {
    width: 0;
    position: absolute;
    right: 15px;
    margin-left: 0;
  }
  .searchbox-open {
    width: 95%;
  }
  .searchbox-input {
    background: rgb(8 17 54);
  }
  .collections_filter_results {
    display: none;
  }
  .individual_collections_panel_left_mobile .collections_filter_results_mobile {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #081136;
    padding: 18px 10px;
  }
  .collection_filter_panel {
    height: calc( 100vh - 100px );
  }
  .page_header h1 {    
    font-size: 32px;
    line-height: 125%;
  }
  .page_header {
    margin-top: 0;
  }
  .container.page_header_container {
    padding: 0;
    overflow: hidden;
  }
  .collections_div_content h2 {
    font-size: 14px;
  }
  .properties_grid {
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .collections_details_content h2 {
    font-size: 16px;
  }
  .collections_details_content {
    margin-top: 15px;
  }
  .collections_details_content p,
  .collections_details_content h5 {
    font-size: 16px;
  }
  .detail_subtitle_line::after {
    width: 80%;
  }
}
@media only screen and (max-width: 640px) {
  .grid_cols {		
		grid-template-columns: repeat(2,minmax(0,1fr));
	}
  .properties_grid {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }
  .detail_subtitle_line::after {
    width: 72%;
  }
  .grp_btns button:nth-child(2) {
    margin-left: 0;
    display: block;
    margin-top: 15px;
  }
  .inp_grp {
    width: 100%;
  }
  .primary_modal .modal-dialog {
    margin: 0;
    max-width: 100%;
  }
  .primary_modal .modal-content {
    height: 100vh;
  }
  .primary_modal .modal-body {    
    padding: 100px 20px 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .primary_modal .modal-body .green_btn {
    max-width: 175px;
    margin: 0 auto;
  }
  .primary_modal .modal-body h2 {
    font-style: italic;
    font-size: 18px;
  }
  .primary_modal .modal-body p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 350px) {
  .collection_filter_panel {
    overflow: auto;
    height: calc( 100vh - 170px );
  }
  .grid_cols {		
		grid-template-columns: repeat(1,minmax(0,1fr));
	}
  .detail_subtitle_line::after {
    width: 56%;
  }
}